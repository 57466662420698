import React, { useState } from 'react'
import { Container, Tab, Nav, Row, Col, Image, Button, DropdownButton, Dropdown, Table } from 'react-bootstrap'
import styles from './About.module.scss'
import HeroMiniBg from './../assets/images/hero_mini_bg.png'
import { FaTwitter, FaTelegram, FaGithub  } from 'react-icons/fa'
import { AiOutlinePlus } from 'react-icons/ai'
import Header from './../components/Header'
import AvatarImage from './../assets/images/avatar.png'
import Drifter from './../assets/images/team/drifter.jpg'
import Professor from './../assets/images/team/professor.jpg'
import Martian from './../assets/images/team/martian.jpg'
import SixtyEight from './../assets/images/team/68.jpg'
import Del from './../assets/images/team/del.jpg'
import Zlatan from './../assets/images/team/zlatan.jpg'
import Favorite from './../assets/images/team/favorite.jpg'
import Bhoomi from './../assets/images/team/bhoomi.jpg'
import ZandOne from './../assets/images/team/zandone.jpg'
import  QuantX from './../assets/images/team/quantx.jpg'
import MengYao from './../assets/images/team/meng.jpg'
import Michael from './../assets/images/team/michael.jpg'
import { useTranslation } from 'react-i18next'

var currentDate = new Date();

function About() {

    const { t, i18n } = useTranslation();

    return (
        <div className={styles.about}>
            {/* HeroMini container begins here */}
            <div className={styles.heroMini}>
                <div className={styles.overlay}>
                    <Container className={styles.heroMiniContainer}>
                            <Header />
                            <div className={styles.content}>
                                <div className={styles.title}>About</div>
                            </div>
                    </Container>
                </div>
            </div>
            {/* HeoMini container ends here */}

            {/* mission container starts here */}
            <div className={styles.mission} id="token">
                <Container className={styles.missionContainer}>
                    <div className={styles.title}> {t('About.Mission.title')} </div>
                    <div className={styles.desc}> {t('About.Mission.desc1')} </div>
                    <div className={styles.desc}> {t('About.Mission.desc2')} </div>
                    <div className={styles.desc}> {t('About.Mission.desc3')} </div>
                </Container>
            </div>
            {/* mission container ends here */}

            {/* contact container starts here */}
            <div className={styles.contact} id="token">
                <Container className={styles.contactContainer}>
                    <div className={styles.desc}> {t('About.ReachOut.title')}</div>
                    <div className={styles.title}>hello@metareset.org</div>
                </Container>
            </div>
            {/* contact container ends here */}

            {/* Team container starts here */}
            <div className={styles.team}>
                <Container className={styles.teamContainer}>
                    <div className={styles.title}>Team</div>
                    <div className={styles.teamListContainer}>
                        {
                            currentDate.getDate() % 2 == 0 ? 
                            <div className={styles.tile}>
                                <img
                                    src={Drifter}
                                    width="auto"
                                    height="96"
                                    className="d-inline-block align-top"
                                    alt="avatar"
                                />
                                <div className={styles.content}>
                                    <div className={styles.name}>Drifter</div>
                                    <div className={styles.role}>Co-founder, Head of Product</div>
                                    <div className={styles.role}>drifter@metareset.org</div>   
                                </div>
                            </div> :
                            <div className={styles.tile}>
                                <img
                                    src={Professor}
                                    width="auto"
                                    height="96"
                                    className="d-inline-block align-top"
                                    alt="avatar"
                                />
                                <div className={styles.content}>
                                    <div className={styles.name}>Professor SD</div>
                                    <div className={styles.role}>{t('About.Team.ProfessorSD.role')}</div>
                                    <div className={styles.role}>professor@metareset.org</div>    
                                </div>  
                            </div>
                        }

                        {
                            currentDate.getDate() % 2 !== 0 ? 
                            <div className={styles.tile}>
                                <img
                                    src={Drifter}
                                    width="auto"
                                    height="96"
                                    className="d-inline-block align-top"
                                    alt="avatar"
                                />
                                <div className={styles.content}>
                                    <div className={styles.name}>Drifter</div>
                                    <div className={styles.role}>Co-founder, Head of Product</div>
                                    <div className={styles.role}>drifter@metareset.org</div>   
                                </div>
                            </div> :
                            <div className={styles.tile}>
                                <img
                                    src={Professor}
                                    width="auto"
                                    height="96"
                                    className="d-inline-block align-top"
                                    alt="avatar"
                                />
                                <div className={styles.content}>
                                    <div className={styles.name}>Professor SD</div>
                                    <div className={styles.role}>{t('About.Team.ProfessorSD.role')}</div>
                                    <div className={styles.role}>professor@metareset.org</div>    
                                </div>  
                            </div>
                        }
                        
                        <div className={styles.tile}>
                            <img
                                src={Martian}
                                width="auto"
                                height="96"
                                className="d-inline-block align-top"
                                alt="avatar"
                            />
                            <div className={styles.content}>
                                <div className={styles.name}>Martian</div>
                                <div className={styles.role}>Creative Director</div>
                            </div>
                        </div>
                        <div className={styles.tile}>
                            <img
                                src={Michael}
                                width="auto"
                                height="96"
                                className="d-inline-block align-top"
                                alt="avatar"
                            />
                            <div className={styles.content}>
                                <div className={styles.name}>Michael</div>
                                <div className={styles.role}>Marketing</div>    
                            </div>
                        </div>
                        <div className={styles.tile}>
                            <img
                                src={Bhoomi}
                                width="auto"
                                height="96"
                                className="d-inline-block align-top"
                                alt="avatar"
                            />
                            <div className={styles.content}>
                                <div className={styles.name}>Bhoomi</div>
                                <div className={styles.role}>Client Relations</div>    
                            </div>
                        </div>
                        <div className={styles.tile}>
                            <img
                                src={MengYao}
                                width="auto"
                                height="96"
                                className="d-inline-block align-top"
                                alt="avatar"
                            />
                            <div className={styles.content}>
                                <div className={styles.name}>Meng Yao</div>
                                <div className={styles.role}>Developer</div>    
                            </div>
                        </div>
                        <div className={styles.tile}>
                            <img
                                src={QuantX}
                                width="auto"
                                height="96"
                                className="d-inline-block align-top"
                                alt="avatar"
                            />
                            <div className={styles.content}>
                                <div className={styles.name}>QuantX</div>
                                <div className={styles.role}>Developer</div>    
                            </div>
                        </div>
                        <div className={styles.tile}>
                            <img
                                src={ZandOne}
                                width="auto"
                                height="96"
                                className="d-inline-block align-top"
                                alt="avatar"
                            />
                            <div className={styles.content}>
                                <div className={styles.name}>ZandOne</div>
                                <div className={styles.role}>Designer</div>    
                            </div>
                        </div>
                        <div className={styles.tile}>
                            <img
                                src={SixtyEight}
                                width="auto"
                                height="96"
                                className="d-inline-block align-top"
                                alt="avatar"
                            />
                            <div className={styles.content}>
                                <div className={styles.name}>SixtyEight</div>
                                <div className={styles.role}>Community Manager</div>    
                            </div>
                        </div>
                        <div className={styles.tile}>
                            <img
                                src={Del}
                                width="auto"
                                height="96"
                                className="d-inline-block align-top"
                                alt="avatar"
                            />
                            <div className={styles.content}>
                                <div className={styles.name}>Del</div>
                                <div className={styles.role}>Moderator</div>    
                            </div>
                        </div>
                        <div className={styles.tile}>
                            <img
                                src={Zlatan}
                                width="auto"
                                height="96"
                                className="d-inline-block align-top"
                                alt="avatar"
                            />
                            <div className={styles.content}>
                                <div className={styles.name}>Zlatan</div>
                                <div className={styles.role}>Moderator</div>    
                            </div>
                        </div>
                        <div className={styles.tile}>
                            <img
                                src={Favorite}
                                width="auto"
                                height="96"
                                className="d-inline-block align-top"
                                alt="avatar"
                            />
                            <div className={styles.content}>
                                <div className={styles.name}>Favorite</div>
                                <div className={styles.role}>Moderator</div>    
                            </div>
                        </div>
                        
                        
                    </div>
                </Container>
            </div>
            {/* Team container ends here */}
        </div>
    )
}

export default About