import React, { useState, useEffect, useRef } from 'react'
import { Container, Form, FormControl, Row, Col, Image, Button, Modal, Dropdown, Table } from 'react-bootstrap'
import styles from './Portal.Home.module.scss'
import UsernameImage from '../../assets/images/aboutbg.jpg'
import GovernanceIcon from '../../assets/images/governanceIcon.png'
import AIIcon from '../../assets/images/aiIcon.png'
import FinanceIcon from '../../assets/images/financeIcon.png'
import EntertainmentIcon from '../../assets/images/entertainmentIcon.png'
import CommerceIcon from '../../assets/images/commerceIcon.png'
import IoTIcon from '../../assets/images/iotIcon.png'
import EducationIcon from '../../assets/images/educationIcon.png'
import AdultIcon from '../../assets/images/adultIcon.png'
import { IoChatbubbleEllipses, IoCheckmarkCircle, IoIosCheckmarkCircle, IoWarning,  } from 'react-icons/io5'
import { Configuration, OpenAIApi } from "openai";
import MetaResetABI from '../../ABI/MetaReset.ABI.json'

import Web3 from 'web3'
import { Alchemy, Network } from "alchemy-sdk";

const config = {
    apiKey: "Lp5vPKQ6Q0hVCkUaTWb8jA9kxBetyLyi",
    network: Network.Goerli,
};
const alchemy = new Alchemy(config);
const web3 = new Web3(Web3.givenProvider);

const MetaResetAddress = "0xb70eaf5d316192881aac8786c90b7907b83f02e8";
const MetaResetContract = new web3.eth.Contract(MetaResetABI, MetaResetAddress);

const configuration = new Configuration({
    organization: "org-nAyOM1qzUtHAm4uBvZ5qgdgf",
    apiKey: "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",
});

const openai = new OpenAIApi(configuration);

const OdysseyRegistryABI = require('../../ABI/OdysseyRegistry.ABI.json')
const OdysseyRegistryAddress = "0xc20AAc02C3Fc9cA531b72aE825947761c00f2554" // mainnet
//const OdysseyRegistryAddress = "0x17F718ad06221940e66321e34416EE914FBb2818" // goerli
const OdysseyRegistryContract = new web3.eth.Contract(OdysseyRegistryABI, OdysseyRegistryAddress);

const initialHistory = [
    {
        user: "AVA-G1",
        message: "Hello, welcome to MetaPortal"
    },
    {
        user: "AVA-G1",
        message: "I am AVA-G1: Odyssey’s governance AI."
    },
    {
        user: "AVA-G1",
        message: "Odyssey's governance initialized. Establishment of protocols and integration of applications is currently in progress."
    }
]

const keywords = ["odyssey", "metareset", "reset", "metapeople", "portal", "metaportal", "land", "houses", "metaverse"]

function PortalHome(props) {

    const [chatMessages, setChatMessages] = useState(initialHistory) // This is the array of messages from the user and the API response
    const [input, setInput] = useState(''); // This is the input from the user
    const [ethBalance, setEthBalance] = useState(0); // This is the input from the user
    const [resetBalance, setResetBalance] = useState(0); // This is the input from the user
    const [ethInUSD, setEthInUSD] = useState(0); // This is the input from the user
    const [resetInUSD, setResetInUSD] = useState(0); // This is the input from the user'
    const [showUsernameRegistration, setShowUsernameRegistration] = useState(false); // This is the input from the user'
    const [usernameStatus, setUsernameStatus] = useState(''); // This is the input from the user'
    const [usernameInput, setUsernameInput] = useState(''); // This is the input from the user
    const [txnHash, setTxnHash] = useState(''); // This is the input from the user
    const [username, setUsername] = useState('')

    const openModal = () => {
        setShowUsernameRegistration(true)
    }

    const closeModal = () => {
        setShowUsernameRegistration(false)
        setUsernameStatus('')
        setUsernameInput('')
    }

    // Function to check if a text has only letters and numbers
    const isAlphaNumeric = (text) => {
        var regExp = /^[a-z0-9]+$/i;
        return regExp.test(text);
    }

    // Function to handle register function
    // Call the setUsername function from the OdysseyRegistry contract with the usernameInput as parameter
    const handleRegister = async () => {
        if(usernameInput == '') return;
        if(!isAlphaNumeric(usernameInput)) {
            return;
        }
        // if this wallet already has username we are not allowing to change it
        // To check this we call the method getUsername from the contract
        const gasPrice = await web3.eth.getGasPrice();
        let gas = 0;
        // get gas estimate. If it fails, the username is already taken. console log the error code
        try {
            gas = await OdysseyRegistryContract.methods.setUsername(usernameInput).estimateGas({from: props.connectedAddress})
            OdysseyRegistryContract.methods.setUsername(usernameInput).send({from: props.connectedAddress, gas: gas, gasPrice: gasPrice})
            .on('transactionHash', function(hash){
                setUsernameStatus('pending');
                setTxnHash(hash);
            })
            .on('receipt', function(receipt){
                setUsernameStatus('success')
            })
            .on('error', function(error){
                console.log("test")
            })
        } catch (error) {
            setUsernameStatus('taken')
        }
    }

    //Function to handle the submit button
    const handleSubmit = async (e) => {
        e.preventDefault();   
        const prompt = input
        setInput('')
        if(prompt == '') return; // If the input is empty, do nothing
        const userMessage =
            {
                user: "User",
                message: prompt
            }
        setChatMessages([...chatMessages, userMessage])

        // Function to check if the prompt contains any of the keywords
        // if it does, we use davinci:ft-metareset-2023-02-27-10-30-05 else we use text-davinci-003
        const checkKeywords = (prompt) => {
            for(let i = 0; i < keywords.length; i++) {
                if(prompt.toLowerCase().includes(keywords[i])) {
                    return true;
                }
            }
            return false;
        }

        const gptModel = checkKeywords(prompt) ? "davinci:ft-metareset-2023-02-27-10-30-05" : "text-davinci-003";
        
        // Function to convert everything in the chatMessages array to a string in the format "User: <message> \n AVA-G1: <message> \n User: <message> \n AVA-G1: <message> \n"
        const chatMessagesToString = (chatMessages) => {
            let chatMessagesString = "";
            for(let i = 0; i < chatMessages.length; i++) {
                chatMessagesString += chatMessages[i].user + ": " + chatMessages[i].message + "\n";
            }
            return chatMessagesString;
        }

        const chatHistory = await chatMessagesToString(chatMessages);

        const gptPrompt = chatHistory + "AVA-G1: " + chatMessages[chatMessages.length - 1] + "\nUser: " + prompt + "\nAVA-G1: ";
        try {
            const completion = await openai.createCompletion({
              model: gptModel,
              prompt: gptPrompt,
              max_tokens: 100,
              temperature: 0.7,
              stop: ["User:", "AVA-G1:"],
            });
            const responseMessage =
                {
                    user: "AVA-G1",
                    message: completion.data.choices[0].text
                }
            setChatMessages(chatMessages => [...chatMessages, responseMessage])
          } catch (error) {
            if (error.response) {
              console.log(error.response.status);
              console.log(error.response.data);
            } else {
              console.log(error.message);
            }
          }
    }

    // This is the array of messages from the user and the API response
    const Chats = chatMessages.map((chat, index) => {
        return(
            chat.user === 'AVA-G1' ? 
            <div className={`${styles.chatItem} ${styles.avaMessage}`}><div className={ chatMessages.length-1 == index ? styles.typed : styles.static}>{chat.message}</div></div> :
            <div className={`${styles.chatItem} ${styles.userMessage}`}><div className={styles.static}>{chat.message}</div></div>
        )
    })

    const chatContainerRef = useRef(null);

    const getBalanceInUSD = async(token, amount) => {
        let response = await fetch(new Request("https://api.livecoinwatch.com/coins/single"), {
            method: "POST",
            headers: new Headers({
                "content-type": "application/json",
                "x-api-key": "0f773665-ae2a-4f85-806f-4635bb4ffe56",
            }),
            body: JSON.stringify({
                currency: "USD",
                code: token,
                meta: true,
            }),
            });
            let data = await response.json();
            let rate = await data.rate.toFixed(4);
            return (rate * amount).toFixed(4);
    }

    useEffect(() => {
        chatContainerRef.current.scrollTo({ top: chatContainerRef.current.scrollHeight, behavior: 'smooth' });
        const initialize = async () => {
            const ethWalletWei = await web3.eth.getBalance(props.connectedAddress);
            const ethWalletBalance = parseFloat(web3.utils.fromWei(ethWalletWei, 'ether'));
            setEthBalance(ethWalletBalance.toFixed(4));

            const ethUSDBalance = await getBalanceInUSD('ETH', ethBalance);
            //console.log(ethUSDBalance)
            setEthInUSD(ethUSDBalance);

            const resetWalletWei = await MetaResetContract.methods.balanceOf(props.connectedAddress).call();
            const resetWalletBalance = parseFloat(web3.utils.fromWei(resetWalletWei, 'ether'));
            setResetBalance(resetWalletBalance.toFixed(4));

            const resetUSD = await getBalanceInUSD('RESET', resetBalance);
            //console.log(resetUSD)
            setResetInUSD(resetUSD);

            // check if this wallet has username registered with odyssey registry contract
            const usernameCheck = await OdysseyRegistryContract.methods.getUsernameByAddress(props.connectedAddress).call();
            if(usernameCheck != '') {
                setUsername(usernameCheck);
            }

        }

        // call the function once in every 5 seconds
        initialize();
      });

    return (
        <div className={styles.portalHome}>
            <div className={`${styles.baseTileContainer} ${styles.ava}`}>
                <div className={styles.chatArea}>
                    <div className={styles.avaProfilePictureContainer}>
                        <div className={styles.outerBorder}>
                            <div className={styles.picture}></div>
                        </div>
                    </div>

                    <div className={styles.chatContainer} ref={chatContainerRef}>
                        {Chats}
                    </div>
                </div>
                <Form className={styles.chatInputContainer} onSubmit={(e) => handleSubmit(e)}>
                    <FormControl className={styles.chatInput} type="text" placeholder="Enter your message" value={input} onChange={(e) => setInput(e.target.value)} />
                    <Button className={styles.chatButton} variant="black" type="submit">
                        <IoChatbubbleEllipses />
                    </Button>
                </Form>
            </div>

            <div className={`${styles.baseTileContainer} ${styles.highlights}`}>
                <Row>
                    <Col xs={12} sm={6} md={3}>
                        <div className={`${styles.commandTileContainer} ${styles.accountBalances}`}>
                            <div className={styles.label}>Account Balances</div>
                            <div className={styles.dataContainer}>
                                <div className={styles.item}>
                                    <div className={styles.value}>{resetBalance} <span className={styles.symbol}>RESET</span></div>
                                    <div className={styles.usdValue}>${resetInUSD} USD</div>
                                </div>
                                <div className={styles.item}>
                                    <div className={styles.value}>{ethBalance} <span className={styles.symbol}>ETH</span></div>
                                    <div className={styles.usdValue}>${ethInUSD} USD</div>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} sm={6} md={3}>
                        <div className={`${styles.commandTileContainer} ${styles.metaPeople}`}>
                            <div className={styles.textContent}>
                                <div className={styles.title}>Get your MetaPeople NFT</div>
                            </div>
                            <a className={styles.button} variant="black" href="/metapeople">Browse</a>
                        </div>
                    </Col>

                    {
                        username == '' ?
                        <Col xs={12} sm={12} md={6}>
                            <div className={`${styles.commandTileContainer} ${styles.metaPeople}`}>
                                <div className={styles.textContent}>
                                    <div className={styles.title}>Register your decentralized username</div>
                                    <div className={styles.desc}>Send payments, send messages, find people, connect with people using Odysseys’s decentralized population registry.</div>
                                </div>
                                <Button className={styles.button} variant="black" onClick={openModal}>Register</Button>
                            </div>
                        </Col> :
                        <Col xs={12} sm={12} md={6}>
                            <div className={`${styles.commandTileContainer} ${styles.metaPeople}`}>
                                <div className={styles.textContent}>
                                    <div className={styles.title}>Welcome {username}!</div>
                                    <div className={styles.desc}>You are now a part of Odyssey's official citizen registry. Please wait for more developments in the following days.</div>
                                </div>
                            </div>
                        </Col>
                    }
                </Row>
            </div>

            <div className={`${styles.baseTileContainer} ${styles.districts}`}>
                <Row className={styles.districtsRow}>
                    <Col xs={12} sm={6} md={3}>
                        <div className={`${styles.commandTileContainer} ${styles.district}`}>
                            <div className={styles.iconContainer}>
                                <img 
                                    src={GovernanceIcon}
                                    height={36}
                                    width={36} 
                                    alt="Governance Icon" />
                            </div>
                            <div className={styles.title}>Governance District</div>
                            <div className={styles.desc}>Vote on proposals, create proposals, participate in governance.</div>
                            <div className={styles.tag}>coming soon</div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <div className={`${styles.commandTileContainer} ${styles.district}`}>
                            <div className={styles.iconContainer}>
                                <img 
                                    src={AIIcon}
                                    height={36}
                                    width={36} 
                                    alt="AI Icon" />
                            </div>
                            <div className={styles.title}>AI District</div>
                            <div className={styles.desc}>Artificial Intelligence services, apps  and partner integrations.</div>
                            <div className={styles.tag}>coming soon</div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <div className={`${styles.commandTileContainer} ${styles.district}`}>
                            <div className={styles.iconContainer}>
                                <img 
                                    src={FinanceIcon}
                                    height={36}
                                    width={36} 
                                    alt="Finance Icon" />
                            </div>
                            <div className={styles.title}>Finance District</div>
                            <div className={styles.desc}>DeFi applications, staking, banking and lending.</div>
                            <div className={styles.tag}>coming soon</div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <div className={`${styles.commandTileContainer} ${styles.district}`}>
                            <div className={styles.iconContainer}>
                                <img 
                                    src={EntertainmentIcon}
                                    height={36}
                                    width={36} 
                                    alt="Entertainment Icon" />
                            </div>
                            <div className={styles.title}>Entertainment District</div>
                            <div className={styles.desc}>Movies, music, news, social applications and more.</div>
                            <div className={styles.tag}>coming soon</div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <div className={`${styles.commandTileContainer} ${styles.district}`}>
                            <div className={styles.iconContainer}>
                                <img 
                                    src={CommerceIcon}
                                    height={36}
                                    width={36} 
                                    alt="Commerce Icon" />
                            </div>
                            <div className={styles.title}>Commerce District</div>
                            <div className={styles.desc}>Digital products, shopping, service exchanges and more.</div>
                            <div className={styles.tag}>coming soon</div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <div className={`${styles.commandTileContainer} ${styles.district}`}>
                            <div className={styles.iconContainer}>
                                <img 
                                    src={IoTIcon}
                                    height={36}
                                    width={36} 
                                    alt="IoT Icon" />
                            </div>
                            <div className={styles.title}>IoT</div>
                            <div className={styles.desc}>Digital twins, simulations services and more.</div>
                            <div className={styles.tag}>coming soon</div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <div className={`${styles.commandTileContainer} ${styles.district}`}>
                            <div className={styles.iconContainer}>
                                <img 
                                    src={EducationIcon}
                                    height={36}
                                    width={36} 
                                    alt="Education Icon" />
                            </div>
                            <div className={styles.title}>Education District</div>
                            <div className={styles.desc}>Partners and other protocols Learn-to-earn applications</div>
                            <div className={styles.tag}>coming soon</div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <div className={`${styles.commandTileContainer} ${styles.district}`}>
                            <div className={styles.iconContainer}>
                                <img 
                                    src={AdultIcon}
                                    height={36}
                                    width={36} 
                                    alt="Adult Icon" />
                            </div>
                            <div className={styles.title}>Adult District</div>
                            <div className={styles.desc}>18+ adult only digital services, applications, chat and more</div>
                            <div className={styles.tag}>coming soon</div>
                        </div>
                    </Col>
                </Row>
            </div>

            <Modal
                show={showUsernameRegistration}
                onHide={() => closeModal()}
                aria-labelledby="example-custom-modal-styling-title"
                dialogClassName="usernameModal"
                centered>
                <Modal.Body>
                <div className="content">
                    {
                        usernameStatus === "success" ?
                        <div className="register">
                            <div className="title">Username successfully registered</div>
                            <div className="desc">Send payments, send messages, find people, connect with people using Odysseys’s decentralized population registry.</div>
                            <div className="inputContainer">
                                <Button className="button" variant="black" onClick={() => closeModal()}>Close</Button>
                            </div>
                        </div> :
                        usernameStatus === "pending" ?
                        <div className="register">
                            <div className="title">Registering Username</div>
                            <a className="link" href={"https://etherscan.io/tx/"+txnHash} target="_blank">View Transaction {txnHash}</a>
                            <div className="inputContainer">
                                <Button className="button" variant="black" onClick={() => closeModal()}>Close</Button>
                            </div>
                        </div> :
                        <div className="register">
                            <div className="title">Register your decentralized username</div>
                            <div className="desc">Send payments, send messages, find people, connect with people using Odysseys’s decentralized population registry.</div>
                            <div className="inputContainer">
                                <input className="inputText" type="text" placeholder="Enter your username" value={usernameInput} onChange={(e) => setUsernameInput(e.target.value)} />
                                {
                                    usernameInput.length >= 7 && isAlphaNumeric(usernameInput) ?
                                    <button className="button" onClick={() => handleRegister()} variant="black">Register</button> :
                                    <button className="button disabledButton">Register</button>
                                }
                            </div>
                            <div className="validtion">
                                <div className="item">
                                    {
                                    usernameInput.length === 0 ? "" :
                                    usernameInput.length < 7 ? <IoWarning className="warning" /> :
                                    usernameInput.length >= 7 ? <IoCheckmarkCircle className="valid" /> : null
                                    }
                                    Minimum 6 characters
                                </div>
                                <div className="item">
                                    {
                                    usernameInput.length === 0 ? "" :
                                    !isAlphaNumeric(usernameInput) ? <IoWarning className="warning" /> :
                                    isAlphaNumeric(usernameInput) ? <IoCheckmarkCircle className="valid" /> : null
                                    }
                                    Letters and numbers only
                                </div>
                            </div>
                            {
                                usernameStatus === "taken" ?
                                <div className="registered">
                                        Username already registered
                                </div> :
                                usernameStatus === "fail" ?
                                <div className="registered">
                                        Username registration failed
                                </div> : null
                            }
                        </div>
                    }
                </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PortalHome