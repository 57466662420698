import React from 'react'
import { Container, Row, Col, Button, DropdownButton, Dropdown } from 'react-bootstrap'
import styles from './Footer.module.scss'
import logo from '../assets/images/logo_white.svg'
import { FaTwitter, FaTelegram, FaGithub, FaMedium  } from 'react-icons/fa'
import { IoLogoWechat } from "react-icons/io5";
import { useTranslation } from 'react-i18next'

function Footer() {

    const { t, i18n } = useTranslation();

    return (
        <div className={styles.footer}>
            <Container className={styles.footerContainer}>
                <Row className={styles.footerTop}>
                    <Col md="4">
                        <div className={styles.logoContainer}>
                            <img
                                src={logo}
                                width="64"
                                height="64"
                                className="d-inline-block align-top"
                                alt="Logo"
                            />
                        </div>
                    </Col>
                    <Col md="4">
                        <div className={styles.linksContainer}>
                                <div className={styles.item}>hello@metareset.org</div>
                                <div className={styles.social}>
                                    <a href="https://twitter.com/metareset" target="_blank" className={styles.item}><FaTwitter /></a>
                                    <a href="https://t.me/RESETverse" target="_blank" className={styles.item}><FaTelegram /></a>
                                    <a href="https://github.com/metareset" target="_blank" className={styles.item}><FaGithub /></a>
                                    <a href="https://medium.com/metareset" target="_blank" className={styles.item}><FaMedium /></a>
                                </div>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className={styles.buttonContainer}>
                        <a href="/banking">
                            <Button variant="outline-grey" size="sm" href="https://app.uniswap.org/#/swap?outputCurrency=0xb70eaf5d316192881aac8786c90b7907b83f02e8" target="_blank">
                                Buy on Uniswap
                            </Button>
                        </a>
                        </div>
                    </Col>
                </Row>
                <Row className={styles.footerBottom}>
                    <Col md="6" className={styles.copyright}>
                        {t('Footer.copyrights')}
                    </Col>
                    <Col md="6" className={styles.website}>
                        <a href="https://www.metareset.org" target="_blank">www.MetaReset.org</a>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Footer