import React, { useState, useEffect, useRef } from 'react'
import { Container, Tab, Nav, Row, Col, Image, Button, DropdownButton, Dropdown, Table } from 'react-bootstrap'
import styles from './Home.module.scss'
import { FaTwitter, FaTelegram, FaGithub, FaArrowUp, FaArrowRight, FaRobot  } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import HeroVideo from './../assets/homenew.mp4'
import WhitepaperOdyssey from './../assets/Whitepaperv1.pdf'
import MetaPeoplePreview from './../assets/images/metapeoplePreview.png'
import PortalPreview from './../assets/MetaWorldPortal.mp4'
import Header from './../components/Header'
import p5 from 'p5'
import TRUNK from 'vanta/dist/vanta.trunk.min'
import DOTS from 'vanta/dist/vanta.dots.min'

// Firebase storage
import { initializeApp } from 'firebase/app';
import { getStorage, ref, getDownloadURL } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCdtfB4UUHFsVJJQ2LpIapfUcU_6WjLXYY",
    authDomain: "metareset-web.firebaseapp.com",
    projectId: "metareset-web",
    storageBucket: "metareset-web.appspot.com",
    messagingSenderId: "574635078106",
    appId: "1:574635078106:web:892eb578525a3b499590e3"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

function Home() {

    const [vantaEffect, setVantaEffect] = useState(null)
    const myRef = useRef(null)

    const [vantaEffectDots, setVantaEffectDots] = useState(null)
    const myRefDots = useRef(null)

    const [DownloadUrl, setDownloadUrl] = useState(null)

    const storage = getStorage();

    useState(() => {
        getDownloadURL(ref(storage, '/Odyssey.zip'))
    .then((url) => {
        setDownloadUrl(url);

        // This can be downloaded directly:
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
        const blob = xhr.response;
        };
        xhr.open('GET', url);
        xhr.send();
    })
    .catch((error) => {
        // Handle any errors
    });
    })


    useEffect(() => {
      if (!vantaEffect) {
        setVantaEffect(TRUNK({
          el: myRef.current,
          p5: p5,
          mouseControls: true,
            touchControls: true,
            gyroControls: false,
            scale: 1.00,
            scaleMobile: 0.50,
            color: 0xdadada,
            backgroundColor: "transparent",
            spacing: 0,
            chaos: 2.50
        }))
      }
      if (!vantaEffectDots) {
        setVantaEffectDots(DOTS({
            el: myRefDots.current,
            mouseControls: true,
            touchControls: true,
            gyroControls: false,
            minHeight: 200.00,
            minWidth: 200.00,
            scale: 1.00,
            spacing: 20,
            size: 1,
            scaleMobile: 1.00,
            showLines: false,
            backgroundColor: 0x0D0D0D
        }))
      }
      return () => {
        if (vantaEffect) vantaEffect.destroy()
      }
    }, [vantaEffect, vantaEffectDots])

    const [marketData, setMarketData] = useState(null);

    useEffect(() => {
        async function fetchData() {
            //   client.getQuotes({symbol: 'RESET'}).then((result) => {
            //     console.log(result.data)
            //     setMarketData(result.datadata);
            //     }).catch((err) => {
            //     console.log(err)
            //     });
            try {
                const response = await fetch("v1/token?chain=ether&address=0xB70eAF5d316192881Aac8786c90B7907b83F02e8", {
                  headers: {
                    "X-API-KEY": "eb1559fd8b73378d5ca903fe0b3867b9"
                  }
                });
                const data = await response.json();
                setMarketData(data.data.RESET[0]);
                console.log("Crypto data:", data.data.RESET[0]);
              } catch (error) {
                console.error("Error fetching data from API:", error);
              }
          }
          fetchData();
    }, []);

    return (
        <div className={styles.home}>
            
            {/* Hero starts here */}
            <div className={styles.hero}>
                <video
                    autoPlay
                    loop
                    muted
                    className={styles.bgVideo}
                    >
                    <source src={HeroVideo} type="video/mp4" />
                </video>
                <div className={styles.overlay}>
                    <Container className={styles.heroContainer}>
                        <Header />
                        <div className={styles.highlightContainer}>
                                <div className={styles.title}>
                                    <div className={styles.gradientText}>Live to Earn</div>
                                </div>
                                <div className={styles.desc}>First ever Web3 powered virtual world governed by AI. Experience the future.</div>
                                <div className={styles.headerButtons}>
                                    <a className={styles.aiTile} target='_blank' href='https://app.xgpt-ai.com/?partner=metareset'>Ask <b>MetaReset AI </b> <FaArrowRight /></a>
                                    <a className={styles.aiTile} target='_blank' href={DownloadUrl}>Download <b>Odyssey Alpha v1.0 </b> <FaArrowRight /></a>
                                </div>
                            </div>
                            <Row className={styles.featureContainer}>
                                <Col md={3}>
                                    <div className={styles.featureTile}>
                                        <div className={styles.title}>Identity</div>
                                        <div className={styles.desc}>MetaPeople NFTs are the identities of our MetaWorld.</div>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className={styles.featureTile}>
                                        <div className={styles.title}>Land</div>
                                        <div className={styles.desc}>Own your lands in our first MetaWorld city “Odyssey”.</div>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className={styles.featureTile}>
                                        <div className={styles.title}>Assets</div>
                                        <div className={styles.desc}>Own Houses, hover cars, building both civilian and commercial assets.</div>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className={styles.featureTile}>
                                        <div className={styles.title}>Social</div>
                                        <div className={styles.desc}>Interact and build your social circle on the MetaWorld.</div>
                                    </div>
                                </Col>
                            </Row>
                    </Container>
                </div>
            </div>
            {/* Hero ends here */}

            {/* New Token Migration Announcement begins here*/}
            <div className={styles.newTokenMigration}>
                <Container className={styles.newTokenMigrationContainer}>
                    <div className={styles.title}>$Reset Token Contract</div>
                    <div className={styles.desc}>Please ensure to use the right MetaReset ($RESET) token contract <span className={styles.highlight}>0xb70eaf5d316192881aac8786c90b7907b83f02e8</span></div>
                    <div className={styles.buttonsContainer}>
                        <Button href="https://etherscan.io/token/0xb70eaf5d316192881aac8786c90b7907b83f02e8" target='_blank' variant='orange' className={styles.btn}>Etherscan</Button>
                        <Button href='https://v2.info.uniswap.org/pair/0x86deb4d07494c773d4cf22515ce09c98d7ed1252' target='_blank' variant='white' className={styles.btn}>Uniswap Trading Pair</Button>
                    </div>
                </Container>
            </div>
            {/* New Token Migration Announcement Ends Here */}

            {/* Odyssey container begins here */}
            <div className={styles.odyssey}>
                <Container className={styles.odysseyContainer} ref={myRef}>
                    <div className={styles.content}>
                        <div className={styles.title}>Odyssey</div>
                        <div className={styles.desc}>First AI powered city of our MetaWorld built on Web3</div>
                        <Button variant="black" href={WhitepaperOdyssey} target="_blank">Whitepaper v 1.0</Button>
                    </div>
                </Container>
                <Container className={styles.downloadContainer}>
                    <div className={styles.textContainer}>
                        <div className={styles.title}>Odyssey Alpha v1.0</div>
                        <div className={styles.desc}>Download the first version of Odyssey Alpha. Experience the future.</div>
                    </div>
                    <div className={styles.buttonsContainer}>
                        <Button variant="black" href={DownloadUrl} target="_blank">Download Odyssey Alpha v1.0</Button>
                    </div>
                </Container>
                <Container className={styles.featureContainer}>
                    <Row>
                        <Col md={4}>
                            <div className={styles.featureTile}>
                                <div className={styles.title}>AI Powered</div>
                                <div className={styles.desc}>MetaWorld will leverage AI advancements at every available opportunity. MetaReset tends to be the first Metaverse with AI first approach.</div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className={styles.featureTile}>
                                <div className={styles.title}>Community Driven</div>
                                <div className={styles.desc}>Most active community members will be identified and rewarded with tax slots in Top 100 community member pool post metaverse launch.</div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className={styles.featureTile}>
                                <div className={styles.title}>Experience Focused</div>
                                <div className={styles.desc}>The metaverse platform that prioritizes user experience. Join us and immerse yourself in new worlds and experiences</div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className={styles.featureTile}>
                                <div className={styles.title}>UBI</div>
                                <div className={styles.desc}>Universal Basic Income for all our MetaWorld dwellers. Live to Earn by owning one of the MetaPeople characters.</div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className={styles.featureTile}>
                                <div className={styles.title}>Governance</div>
                                <div className={styles.desc}>Steering committee for governance decisions and directing the strategic direction of MetaReset post metaverse launch</div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className={styles.featureTile}>
                                <div className={styles.title}>Channel Partners</div>
                                <div className={styles.desc}>We help potential blockchain and non-blockchain projects to establish their presence on the metaverse by integrating with our MetaWorld.</div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* Odyssey container ends here */}

            {/* MetaPeople container begins here */}
            <div className={styles.metaPeople}>
                <Container className={styles.metaPeopleContainer}>
                    <div className={styles.title}><span className={styles.light}>Meta</span><span className={styles.thick}>People</span> NFTs</div>
                    <div className={styles.desc}>Choose your avatar and identity for the MetaWorld</div>
                    <div className={styles.highlight}>10K Unique Characters Collection</div>
                    <img
                        src={MetaPeoplePreview}
                        width="100%"
                        height="auto"
                        className="d-inline-block align-top"
                        alt="MetaReset Logo" />
                    <div className={styles.buttonsContainer}>
                        <Button variant="orange" href="https://www.youtube.com/watch?v=zMHei4Qk5bw" target="_blank">Watch Trailer</Button>
                        <Button variant="black" href="/metapeople" target="_blank">Mint yours now</Button>
                    </div>
                </Container>
            </div>
            {/* MetaPeople container ends here */}

            {/* Portal container begins here */}
            <div className={styles.portal}>
                <Container className={styles.portalContainer}>
                    <div className={styles.title}>Odyssey Portal</div>
                    <div className={styles.highlight}> in Apple Vision Pro</div>
                    <div className={styles.desc}>Experience the first ever pre-fabricated metaverse in Apple VR. A gateway to metaverse adoption.</div>
                    <div className={styles.desc}><b>Launching in Q4 2023 post Apple VR Release</b></div>
                    <a href="/portal" target="_blank">Explore Technical Preview</a>
                    <video height="700" width="auto" controls className={styles.portalVideo}>
                        <source src={PortalPreview} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div className={styles.tags}>AI Powered Chatbot | DeFi Banking | IAAS | Decentralized Messaging | Social Networking | Feed | Universal Basic Income | Games | Assets Trading</div>
                </Container>
            </div>
            {/* Portal container ends here */}

            {/* Reset container begins here */}
            <div className={styles.reset} ref={myRefDots}>
                <Container className={styles.resetContainer}>
                    <Row>
                        <Col md={9} className={styles.content}>
                            <div className={styles.title}>$RESET Ecosystem Token</div>
                            <div className={styles.desc}>The MetaReset ecosystem will use $Reset as its currency and index. $Reset is an important token similar to other currencies such as USD and EUR. It will be used by MetaPeople for daily transactions, trading resources, and other activities. $Reset will also serve as the governance, utility, and economic layer token for MetaReset. All community rewards, taxes, NFT staking, and MetaFamily rewards will be distributed in $Reset.</div>
                            <div className={styles.buttonsContainer}>
                                <Button variant="black" href="https://app.uniswap.org/#/swap?outputCurrency=0xb70eaf5d316192881aac8786c90b7907b83f02e8" target="_blank">Buy on Uniswap</Button>
                                <Button variant="black" href="https://etherscan.io/address/0xb70eaf5d316192881aac8786c90b7907b83f02e8" target="_blank">Token Contract <span className={styles.address}>0xb70...2e8</span></Button>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className={styles.tileContainer}>
                                <div className={styles.title}>Taxation</div>
                                <div className={styles.dataContainer}>
                                    <div className={styles.dataTile}>
                                        <div className={styles.label}>Tax Status</div>
                                        <div className={styles.value}>Active</div>
                                    </div>
                                    <div className={styles.dataTile}>
                                        <div className={styles.label}>Total Tax</div>
                                        <div className={styles.value}>6%</div>
                                    </div>
                                    <div className={styles.dataTile}>
                                        <div className={styles.label}>Development Tax</div>
                                        <div className={styles.value}>2%</div>
                                    </div>
                                    <div className={styles.dataTile}>
                                        <div className={styles.label}>Community Rewards</div>
                                        <div className={styles.value}>2%</div>
                                    </div>
                                    <div className={styles.dataTile}>
                                        <div className={styles.label}>Auto Liquidity</div>
                                        <div className={styles.value}>2%</div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {/* <div className={`${styles.marketDataContainer} ${styles.tileContainer}`}> */}
                        {
                            // marketData == null ? '' :
                            // <Row className='gx-2 gy-0'>
                            //     <Col md={2}>
                            //         <div className={styles.dataTile}>
                            //             <div className={styles.label}>Price</div>
                            //             <div className={styles.valueContainer}>
                            //                 <div className={styles.value}>${marketData.quote.USD.price ? marketData.quote.USD.price.toFixed(6) : ''}</div>
                            //                 <div className={`${styles.change} ${marketData.quote.USD.percent_change_24h > 1 ? styles.positive : styles.negative}`}>
                            //                     { marketData.quote.USD.percent_change_24h > 0 ? <FaArrowUp /> : <FaArrowDown /> } {((marketData.quote.USD.percent_change_24h) * 100).toFixed(2)}%</div>
                            //             </div>
                            //         </div>
                            //     </Col>
                            //     <Col md={2}>
                            //         <div className={styles.dataTile}>
                            //             <div className={styles.label}>24h Volume</div>
                            //             <div className={styles.valueContainer}>
                            //                 <div className={styles.value}>${marketData.quote.USD.volume_24h ? marketData.quote.USD.volume_24h.toFixed(2) : "0.00"}</div>
                            //             </div>
                            //         </div>
                            //     </Col>
                            //     <Col md={3}>
                            //         <div className={styles.dataTile}>
                            //             <div className={styles.label}>MarketCap</div>
                            //             <div className={styles.value}>${marketData.quote.USD.fully_diluted_market_cap ? (marketData.quote.USD.fully_diluted_market_cap/1000000).toFixed(2) : ''} Million</div>
                            //         </div>
                            //     </Col>
                            //     <Col md={2}>
                            //         <div className={styles.dataTile}>
                            //             <div className={styles.label}>Total Supply</div>
                            //             <div className={styles.value}>1 Billion RESET</div>
                            //         </div>
                            //     </Col>
                            //     <Col md={3}>
                            //         <div className={styles.dataTile}>
                            //             <div className={styles.label}>Circulating Supply</div>
                            //             <div className={styles.value}>585,434,00 RESET</div>
                            //         </div>
                            //     </Col>
                            //     {/* <Col md={2}>
                            //         <div className={styles.dataTile}>
                            //             <div className={styles.label}>ATH</div>
                            //             <div className={styles.value}>${marketData.allTimeHighUSD.toFixed(6)}</div>
                            //         </div>
                            //     </Col> */}
                            // </Row>
                        }
                    {/* </div> */}
                </Container>
            </div>
            {/* Reset container ends here */}

        </div>
    )
}

export default Home