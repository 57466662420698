import React from 'react'
import styles from './App.module.scss'
import Home from './pages/Home'
import Token from './pages/Token'
import Roadmap from './pages/Roadmap'
import Community from './pages/Community'
import About from './pages/About'
import MetaPeople from './pages/MetaPeople'
import Footer from './components/Footer'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import './i18n'
import Portal from './pages/Portal/Portal'

function App() { 
  return (
    <div className={styles.App}>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/token" element={<Token />} />
          <Route exact path="/roadmap" element={<Roadmap />} />
          <Route exact path="/community" element={<Community />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/metapeople" element={<MetaPeople />} />
          <Route exact path="/portal" element={<Portal />} />
          {/* <Route exact path="/metapeople" element={<MetaPeople />} />
          <Route exact path="/docs" element={<Docs />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/blog" element={<Blog />} /> */}
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
