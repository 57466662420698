import React, { useState, useRef, useEffect } from 'react'
import { Container, Tab, Nav, Row, Col, Image, Button, DropdownButton, Dropdown, Table } from 'react-bootstrap'
import styles from './Token.module.scss'
import Header from './../components/Header'
import { FaArrowUp, FaArrowDown  } from 'react-icons/fa'
import HeroMiniBg from './../assets/images/hero_mini_bg.png'
import UniswapIcon from './../assets/images/uniswap.png'
import EtherscanIcon from './../assets/images/etherscan.png'
import DextoolsIcon from './../assets/images/dextools.png'
import TrustswapIcon from './../assets/images/trustswap.png'
import { useTranslation } from 'react-i18next'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import TOPOLOGY from 'vanta/dist/vanta.topology.min'
import CMCLogo from './../assets/images/cmclogo.png'
import CGLogo from './../assets/images/cglogo.png'
import LCWLogo from './../assets/images/lcwlogo.png'
import NomicsLogo from './../assets/images/nomicslogo.png'

const ChartData = {
    labels: ['Circulating Supply', 'Advisors', 'Partners', 'Team', 'Reserve for Future'],
    datasets: [
      {
        data: [65.72, 5, 10, 12, 7.28],
        backgroundColor: ['#31D7FB', '#1B97EE', '#1E52B2', '#153267', '#142546'],
        borderColor: 'transparent',
      }
    ],
  };


const ChartOptions= {
    animations: {
        tension: {
          duration: 1000,
          easing: 'linear',
          from: 1,
          to: 0,
          loop: true
        }
    },
    cutout: '70%',
    hoverOffset: 20,
    radius: '100%',
    backgroundColor: 'red',
    layout: {
        padding: 20,
    },
    plugins: {
        legend: {
            display: false,
        }
    },
}

ChartJS.register(ArcElement, Tooltip, Legend);

function Token() {

    const { t, i18n } = useTranslation();
    const [marketData, setMarketData] = useState(null);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch("/v2/cryptocurrency/quotes/latest?symbol=RESET", {
                  headers: {
                    "X-CMC_PRO_API_KEY": "de77fcd2-f919-4e1e-9396-15265bb887c0"
                  }
                });
                const data = await response.json();
                setMarketData(data.data.RESET[0]);
                console.log("Crypto data:", data.data.RESET[0]);
              } catch (error) {
                console.error("Error fetching data from API:", error);
              }
          }
          fetchData();
    }, []);


    return (
        <div className={styles.token}>
            {/* HeroMini container begins here */}
            <div className={styles.heroMini}>
                <div className={styles.overlay}>
                    <Container className={styles.heroMiniContainer}>
                            <Header />
                            <div className={styles.content}>
                                <div className={styles.title}>Token</div>
                                <div className={styles.desc}><span className={styles.light}>Meta</span><span className={styles.thick}>Reset</span> $RESET</div>
                                <div className={styles.tileContainer}>
                                    {
                                        marketData == null ? '' :
                                        <Row className='gx-2 gy-0'>
                                            <Col md={2}>
                                                <div className={styles.dataTile}>
                                                    <div className={styles.label}>Price</div>
                                                    <div className={styles.valueContainer}>
                                                        <div className={styles.value}>${marketData.quote.USD.price ?  marketData.quote.USD.price.toFixed(6) : ''}</div>
                                                        <div className={`${styles.change} ${marketData.quote.USD.percent_change_24h > 0 ? styles.positive : styles.negative}`}>
                                                            { marketData.quote.USD.percent_change_24h > 0 ? <FaArrowUp /> : <FaArrowDown /> } {((marketData.quote.USD.percent_change_24h) * 100).toFixed(2)}%</div>
                                                        </div>
                                                </div>
                                            </Col>
                                            <Col md={2}>
                                                <div className={styles.dataTile}>
                                                    <div className={styles.label}>24h Volume</div>
                                                    <div className={styles.valueContainer}>
                                                        <div className={styles.value}>${marketData.quote.USD.volume_24h ? marketData.quote.USD.volume_24h.toFixed(2) : '0.00'}</div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                <div className={styles.dataTile}>
                                                    <div className={styles.label}>MarketCap</div>
                                                    <div className={styles.value}>${marketData.quote.USD.fully_diluted_market_cap ? (marketData.quote.USD.fully_diluted_market_cap/1000000).toFixed(2) : ''} Million</div>
                                                </div>
                                            </Col>
                                            <Col md={2}>
                                                <div className={styles.dataTile}>
                                                    <div className={styles.label}>Total Supply</div>
                                                    <div className={styles.value}>1 Billion RESET</div>
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                <div className={styles.dataTile}>
                                                    <div className={styles.label}>Circulating Supply</div>
                                                    <div className={styles.value}>585,434,00 RESET</div>
                                                </div>
                                            </Col>
                                    </Row>
                                    }
                                </div>
                            </div>
                    </Container>
                </div>
            </div>
            {/* HeoMini container ends here */}

            {/* Token distribution begins here */}
            <div className={styles.tokenDistribution}>
                <Container className={styles.tokenDistributionContainer}>
                        
                            <div className={styles.title}><span className={styles.light}>Token</span> Distribution</div>
                        
                            <div className={styles.chartContainer}>
                                <div className={styles.chartInnerContainer}>
                                    <div className={styles.textContainer}>
                                        <div className={styles.label}>Total Supply</div>
                                        <div className={styles.value}>1 Billion</div>
                                    </div>
                                    <Doughnut data={ChartData} options={ChartOptions} />
                                </div>
                            </div>

                            <Row className={styles.legendContainer}>
                                <Col md={4} className={styles.item}>
                                    <div className={styles.label}>Ciruclating Supply <span className={styles.thick}>65.72%</span></div>
                                    <div className={styles.value}>657,246,000 RESET</div>
                                </Col>
                                <Col md={4} className={styles.item}>
                                    <div className={styles.label}>Advisors <span className={styles.thick}>5.00%</span></div>
                                    <div className={styles.value}>50,000,000 RESET</div>
                                </Col>
                                <Col md={4} className={styles.item}>
                                    <div className={styles.label}>Partners <span className={styles.thick}>10.00%</span></div>
                                    <div className={styles.value}>100,000,000 RESET</div>
                                </Col>
                                <Col md={4} className={styles.item}>
                                    <div className={styles.label}>Team <span className={styles.thick}>12.00%</span></div>
                                    <div className={styles.value}>120,000,000 RESET</div>
                                </Col>
                                <Col md={4} className={styles.item}>
                                    <div className={styles.label}>Future Reserve <span className={styles.thick}>7.27%</span></div>
                                    <div className={styles.value}>72,754,000 RESET</div>
                                </Col>
                            </Row>
                        
                </Container>
            </div>
            {/* Token distribution ends here */}

            {/* Links container starts here */}
            <div className={styles.links}>
                <Container className={styles.linksContainer}>
                    <div className={styles.overlay}>
                        <Row className="gx-3">
                            <Col>
                                <a href="https://v2.info.uniswap.org/pair/0x86deb4d07494c773d4cf22515ce09c98d7ed1252" target="_blank" className={styles.tile}>
                                    <img
                                        src={UniswapIcon}
                                        width="auto"
                                        height="48"
                                        className="d-inline-block align-top"
                                        alt="MetaReset Logo" />
                                    <div className={styles.title}>Uniswap</div>
                                </a>
                            </Col>
                            <Col>
                                <a href="https://etherscan.io/address/0xb70eaf5d316192881aac8786c90b7907b83f02e8" target="_blank" className={styles.tile}>
                                    <img
                                        src={EtherscanIcon}
                                        width="auto"
                                        height="48"
                                        className="d-inline-block align-top"
                                        alt="MetaReset Logo" />
                                    <div className={styles.title}>Etherscan</div>
                                </a>
                            </Col>
                            <Col>
                                <a href="https://www.dextools.io/app/en/ether/pair-explorer/0x86deb4d07494c773d4cf22515ce09c98d7ed1252" target="_blank" className={styles.tile}>
                                    <img
                                        src={DextoolsIcon}
                                        width="auto"
                                        height="48"
                                        className="d-inline-block align-top"
                                        alt="MetaReset Logo" />
                                    <div className={styles.title}>Dextools</div>
                                </a>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <Container className={styles.logoContainer}>
                    <Row>
                        <Col>
                            <a href="https://coinmarketcap.com/dexscan/ethereum/0x86deb4d07494c773d4cf22515ce09c98d7ed1252/" target="_blank"><img src={CMCLogo} height={40} /></a>
                        </Col>
                        <Col>
                            <a href="https://www.geckoterminal.com/eth/pools/0x86deb4d07494c773d4cf22515ce09c98d7ed1252" target="_blank"><img src={CGLogo} height={40} /></a>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* Links container ends here */}
        </div>
    )
}

export default Token