import React, { useState } from 'react'
import { Container, Tab, Nav, Row, Col, Image, Button, DropdownButton, Dropdown, Table } from 'react-bootstrap'
import styles from './Roadmap.module.scss'
import Header from './../components/Header'
import { Chrono } from 'react-chrono'
import HeroMiniBg from './../assets/images/hero_mini_bg.png'
import RoadmapImage from './../assets/images/roadmapImage.png'
import ChronoIcon from './../assets/images/chronoicon.svg'
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next'

function Roadmap() {

    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const { t, i18n } = useTranslation();

    return (
        <div className={styles.roadmap}>
            {/* HeroMini container begins here */}
            <div className={styles.heroMini}>
                <div className={styles.overlay}>
                    <Container className={styles.heroMiniContainer}>
                            <Header />
                            <div className={styles.content}>
                                <div className={styles.title}>Roadmap</div>
                            </div>
                    </Container>
                </div>
            </div>
            {/* HeoMini container ends here */}

            {/* Timeline container starts here */}
            <div className={styles.timeline}>
                <Container className={styles.timelineContainer}>
                    <div className={styles.title}><span className={styles.light}>Where we are,</span> how far we have came <span className={styles.light}>&</span> <span className={styles.thick}>where we are heading towards</span></div>
                    <div className={styles.quarterListContainer}>
                        <Row className={styles.quarterContainer}>
                            <Col md={3} className={styles.title}>January 2024</Col>
                            <Col md={9} className={styles.cardContainer}>
                                <div className={styles.outerContainer}>
                                    <div className={`${styles.listItem} ${styles.pending}`}>
                                        <div className={styles.status}></div>
                                        <div className={styles.task}>Odyssey Teaser</div>
                                    </div>
                                    <div className={`${styles.listItem} ${styles.pending}`}>
                                        <div className={styles.status}></div>
                                        <div className={styles.task}>Website Updates</div>
                                    </div>
                                    <div className={`${styles.listItem} ${styles.pending}`}>
                                        <div className={styles.status}></div>
                                        <div className={styles.task}>Reset - XGPT Custom Fine Tuned Model</div>
                                    </div>
                                    <div className={`${styles.listItem} ${styles.pending}`}>
                                        <div className={styles.status}></div>
                                        <div className={styles.task}>Residential houses Walkthrough demo</div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className={styles.quarterContainer}>
                            <Col md={3} className={styles.title}>February 2024</Col>
                            <Col md={9} className={styles.cardContainer}>
                                <div className={styles.outerContainer}>
                                    <div className={`${styles.listItem} ${styles.pending}`}>
                                        <div className={styles.status}></div>
                                        <div className={styles.task}>Introduction to Odyssey Governance</div>
                                    </div>
                                    <div className={`${styles.listItem} ${styles.pending}`}>
                                        <div className={styles.status}></div>
                                        <div className={styles.task}>Odyssey Citizenship Protocol Preview</div>
                                    </div>
                                    <div className={`${styles.listItem} ${styles.pending}`}>
                                        <div className={styles.status}></div>
                                        <div className={styles.task}>$15,000 Liquidity Pool Addition</div>
                                    </div>
                                    <div className={`${styles.listItem} ${styles.pending}`}>
                                        <div className={styles.status}></div>
                                        <div className={styles.task}>Odyssey Valentine Special Announcement</div>
                                    </div>
                                    <div className={`${styles.listItem} ${styles.pending}`}>
                                        <div className={styles.status}></div>
                                        <div className={styles.task}>Odyssey Hover Car demo</div>
                                    </div>
                                    <div className={`${styles.listItem} ${styles.pending}`}>
                                        <div className={styles.status}></div>
                                        <div className={styles.task}>Odyssey Web3 + AI Environment Protocol</div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className={styles.quarterContainer}>
                            <Col md={3} className={styles.title}>March 2024</Col>
                            <Col md={9} className={styles.cardContainer}>
                                <div className={styles.outerContainer}>
                                    <div className={`${styles.listItem} ${styles.pending}`}>
                                        <div className={styles.status}></div>
                                        <div className={styles.task}>Odyssey Full walktrhough demo</div>
                                    </div>
                                    <div className={`${styles.listItem} ${styles.pending}`}>
                                        <div className={styles.status}></div>
                                        <div className={styles.task}>Odyssey Pre-Fabricated Assets Whitepaper</div>
                                    </div>
                                    <div className={`${styles.listItem} ${styles.pending}`}>
                                        <div className={styles.status}></div>
                                        <div className={styles.task}>Website v4.0</div>
                                    </div>
                                    <div className={`${styles.listItem} ${styles.pending}`}>
                                        <div className={styles.status}></div>
                                        <div className={styles.task}>Partnership Anncouncements</div>
                                    </div>
                                    <div className={`${styles.listItem} ${styles.pending}`}>
                                        <div className={styles.status}></div>
                                        <div className={styles.task}>MetaReset Dev Updates</div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className={styles.quarterContainer}>
                            <Col md={3} className={styles.title}>April 2024</Col>
                            <Col md={9} className={styles.cardContainer}>
                                <div className={styles.outerContainer}>
                                    <div className={`${`${styles.listItem} ${styles.pending}`} ${styles.pending}`}>
                                        <div className={styles.status}></div>
                                        <div className={styles.task}>Citizens of Odyssey Trailer</div>
                                    </div>
                                    <div className={`${styles.listItem} ${styles.pending}`}>
                                        <div className={styles.status}></div>
                                        <div className={styles.task}>Odyssey Residential and Commercial Trailer</div>
                                    </div>
                                    <div className={`${styles.listItem} ${styles.pending}`}>
                                        <div className={styles.status}></div>
                                        <div className={styles.task}>Citizens of Odyssey Release</div>
                                    </div>
                                    <div className={`${styles.listItem} ${styles.pending}`}>
                                        <div className={styles.status}></div>
                                        <div className={styles.task}>Partnership Accouncements</div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className={styles.quarterContainer}>
                            <Col md={3} className={styles.title}>May 2024</Col>
                            <Col md={9} className={styles.cardContainer}>
                                <div className={styles.outerContainer}>
                                    <div className={`${`${styles.listItem} ${styles.pending}`} ${styles.pending}`}>
                                        <div className={styles.status}></div>
                                        <div className={styles.task}>Odyssey Trailer</div>
                                    </div>
                                    <div className={`${styles.listItem} ${styles.pending}`}>
                                        <div className={styles.status}></div>
                                        <div className={styles.task}>Odyssey Map Walkthrough</div>
                                    </div>
                                    <div className={`${styles.listItem} ${styles.pending}`}>
                                        <div className={styles.status}></div>
                                        <div className={styles.task}>Partnership Integration Phase I</div>
                                    </div>
                                    <div className={`${styles.listItem} ${styles.pending}`}>
                                        <div className={styles.status}></div>
                                        <div className={styles.task}>Odyssey Beta Launch</div>
                                    </div>
                                    <div className={`${styles.listItem} ${styles.pending}`}>
                                        <div className={styles.status}></div>
                                        <div className={styles.task}>Partnership Anncouncements</div>
                                    </div>
                                    <div className={`${styles.listItem} ${styles.pending}`}>
                                        <div className={styles.status}></div>
                                        <div className={styles.task}>Odyssey Residential and Commercial Release</div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className={styles.quarterContainer}>
                            <Col md={3} className={styles.title}>June 2024</Col>
                            <Col md={9} className={styles.cardContainer}>
                                <div className={styles.outerContainer}>
                                    <div className={`${`${styles.listItem} ${styles.pending}`} ${styles.pending}`}>
                                        <div className={styles.status}></div>
                                        <div className={styles.task}>Live2Earn Deep Dive 101</div>
                                    </div>
                                    <div className={`${styles.listItem} ${styles.pending}`}>
                                        <div className={styles.status}></div>
                                        <div className={styles.task}>Partnership Integrations Phase II</div>
                                    </div>
                                    <div className={`${styles.listItem} ${styles.pending}`}>
                                        <div className={styles.status}></div>
                                        <div className={styles.task}>Odyssey Hover Cars public launch</div>
                                    </div>
                                    <div className={`${styles.listItem} ${styles.pending}`}>
                                        <div className={styles.status}></div>
                                        <div className={styles.task}>Odyssey 3D Virtual Humanoid AI (AVA) Launch</div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            {/* Timeline containers ends here */}

        </div>
    )
}

export default Roadmap