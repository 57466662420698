import React, { useState } from 'react'
import { Container, Nav, Navbar, NavDropdown, DropdownButton, ButtonGroup, Dropdown } from 'react-bootstrap'
import { NavLink, useLocation } from 'react-router-dom'
import styles from './Header.module.scss'
import logo from '../logo.svg'
import { FaTwitter, FaTelegram } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import Config from '../services/config'

function Header(props) {

    const location = useLocation();
    const { t, i18n } = useTranslation();

    const address = props.address ? (props.address.slice(0, 6) + '...' + props.address.slice(-4)) : '';

    const NavMenuRegular = 
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className={`${styles.menuItemContainer} ${'ms-auto'}`}>
                {/* <NavLink exact className={({ isActive }) => `${styles.menuItem} ${isActive ? styles.active : ''}`} to="/">{t('Header.Explore')}</NavLink> */}
                {/* <NavDropdown 
                    title={t('Header.Explore')} 
                    id="nav-dropdown"
                    className={styles.menuItem}
                    onSelect={value => i18n.changeLanguage(value)}>
                    <NavDropdown.Item className={styles.menuItem} eventKey="metapeople" href="/metapeople">{t('Header.MetaPeople')}</NavDropdown.Item>
                </NavDropdown> */}
                <NavLink exact className={({ isActive }) => `${styles.menuItem} ${isActive ? styles.active : ''}`} to="/token">{t('Header.Token')}</NavLink>
                <NavLink exact className={({ isActive }) => `${styles.menuItem} ${isActive ? styles.active : ''}`} to="/roadmap">{t('Header.Roadmap')}</NavLink>
                <NavLink exact className={({ isActive }) => `${styles.menuItem} ${isActive ? styles.active : ''}`} to="/community">{t('Header.Community')}</NavLink>
                <NavLink exact className={({ isActive }) => `${styles.menuItem} ${isActive ? styles.active : ''}`} to="/about">{t('Header.About')}</NavLink>
                {
                    location.pathname === '/portal' ? '' : <NavLink exact className={styles.launchButton} to="/portal">Launch Portal</NavLink>
                }
                {/* <NavDropdown 
                        title={Config.lnNames[i18n.language]} 
                        id="nav-dropdown"
                        className={styles.menuItem}
                        onSelect={value => i18n.changeLanguage(value)}>
                        <NavDropdown.Item className={styles.menuItem} eventKey="en">{t('Header.English')}</NavDropdown.Item>
                        <NavDropdown.Item className={styles.menuItem} eventKey="ru">{t('Header.Russian')}</NavDropdown.Item>
                </NavDropdown> */}
                <div className={styles.social}>
                    <a href="https://twitter.com/metareset" target="_blank" className={styles.item}><FaTwitter /></a>
                    <a href="https://t.me/RESETverse" target="_blank" className={styles.item}><FaTelegram /></a>
                </div>
            </Nav>
        </Navbar.Collapse>

    const NavMenuPortal = 
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className={`${styles.menuItemContainer} ${'ms-auto'}`}>
                <div className={styles.connectedAddress}>Connected to <span className={styles.address}>{address}</span></div>
            </Nav>
        </Navbar.Collapse>

    return (
        <Navbar className={styles.header} collapseOnSelect expand="lg">
            <div className={styles.headerContainer}>
                <Navbar.Brand className={styles.brand} href="/">
                    <img
                        src={logo}
                        width="auto"
                        height="42"
                        className="d-inline-block align-top"
                        alt="MetaReset Logo"
                    />
                </Navbar.Brand>
                {
                    props.address ? NavMenuPortal : 
                    props.mode == 'portal' ? '' : NavMenuRegular
                }
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            </div>
        </Navbar>
    )
}

export default Header