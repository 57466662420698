import React, { useState, useEffect } from 'react'
import { Container, Tab, Nav, Row, Col, Image, Button, DropdownButton, Dropdown, Table } from 'react-bootstrap'
import styles from './Portal.module.scss'
import Header from '../../components/Header'
import Metamask from '../../assets/images/metamask.png'
import PortalPreview from '../../assets/images/portalPreview.png'
import { IoStopCircleSharp } from 'react-icons/io5'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import detectEthereumProvider from '@metamask/detect-provider';
import PortalHome from './Portal.Home'

function PortalLanding() {

    const [status, setStatus] = useState('notConnected')
    const [connectedAddress, setConnectedAddress] = useState('')

    useEffect(() => {
        const init = async () => {
            const provider = await detectEthereumProvider();
            if (provider) {
                // From now on, this should always be true:
                // provider === window.ethereum
                provider.on('accountsChanged', accounts => {
                    setConnectedAddress(accounts[0]);
                  });
            } else {
                console.log('Please install MetaMask!');
                setStatus('notInstalled')
            }
        }
        init()
    }, [])

    const handleConnect = async () => {
        try {
            // Detect if Metamask is installed
            if (window.ethereum) {
            // Set status to 'connecting'
            setStatus('connecting');
            
            // Request account access from Metamask
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            setConnectedAddress(accounts[0])
            
            // Set status to 'connected' upon successful connection
            setStatus('connected');

            } else {
            // Set status to 'notInstalled' if Metamask is not installed
            setStatus('notInstalled');
            }
        } catch (error) {
            console.error(error);
        }
    }
    

    const MetaMaskConnect = 
        <div className={styles.content}>
            <div className={styles.title}>Meta<span className={styles.thick}>Portal</span></div>
            <div className={styles.desc}>Gateway to Odyssey | Powered by AI | Built with Community</div>
            <Button size='lg' className={styles.buttonWithImage} variant="black" onClick={handleConnect}>
                <img 
                    src={Metamask}
                    height={24}
                    width={24} 
                    alt="Metamask logo" />
                Connect with MetaMask
            </Button>
            <div className={styles.preview}>
                <img
                    src={PortalPreview}
                    height="auto"
                    width="98%"
                    alt="Portal Preview"
                    className={styles.previewImage}
                    />
            </div>
        </div>

    const MetaMaskConnecting =
        <div className={styles.connection}>
            <img 
                src={Metamask}
                height={96}
                width={96} 
                alt="Metamask logo" />
                <div>
                    <div className={styles.statusBlink}>Connecting</div>
                    <div className={styles.desc}>Please check your MetaMask wallet to authorize.</div>
                </div>
        </div>

    const MetaMaskNotInstalled =
        <div className={styles.connection}>
            <img 
                src={Metamask}
                height={96}
                width={96} 
                alt="Metamask logo" />
                <div>
                    <div className={styles.status}>Metamask Not Installed</div>
                    <div className={styles.desc}>Please install metamask extension to connect</div>
                </div>
        </div>

    const Portal = <PortalHome connectedAddress={connectedAddress} />

    return (
        <div className={styles.landingPage}>
            <div className={styles.overlay}>

                {/* Content container starts here */}
                <Container className={styles.landingPageContainer}>
                    <Header mode="portal" address={status == "connected" ? connectedAddress : null} />
                    { 
                        status === 'notInstalled'? MetaMaskNotInstalled :
                        status === 'notConnected'? MetaMaskConnect :
                        status === 'connecting'? MetaMaskConnecting :
                        status === 'connected'? Portal : ''
                    }
                </Container>
                {/* Content container ends here */}

            </div>
        </div>
    )
}

export default PortalLanding