import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Container, Tab, Nav, Row, Col, Accordion, Dropdown, Button, Modal } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import styles from './MetaPeople.module.scss'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import HeroMiniBg from './../assets/images/hero_mini_bg.png'
import Header from '../components/Header';
import { useTranslation } from 'react-i18next'
import MetaPeopleJSON from './../assets/MetaPeople'
import MetadataJSON from './../assets/MetaData.json'
import InfiniteScroll from "react-infinite-scroll-component";
import { Alchemy, Network } from "alchemy-sdk";
import Web3 from 'web3'
import { AiFillCarryOut } from 'react-icons/ai'
import HeroVideo from './../assets/hero.mp4'


const config = {
    apiKey: "Lp5vPKQ6Q0hVCkUaTWb8jA9kxBetyLyi",
    network: Network.ETH_MAINNET,
};
const alchemy = new Alchemy(config);


function shuffle(array) {
    console.log("shuffling")
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

  const MetaPeopleNFTs = shuffle(MetaPeopleJSON.NFTs)

  const genderOptions = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
  ];

const skinOptions = [
    { value: 'PaleWhite', label: 'White' },
    { value: 'Beige', label: 'Beige' },
    { value: 'Olive', label: 'Olive' },
    { value: 'Brown', label: 'Brown' },
    { value: 'DarkBrown', label: 'Dark Brown' },
    { value: 'Black', label: 'Black' }
  ];

const eyeOptions = [
    { value: 'Large', label: 'Large' },
    { value: 'Medium', label: 'Medium' },
    { value: 'Small', label: 'Small' }
  ];

const hairStyleOptions = [
    { value: 'Pony', label: 'Pony' },
    { value: 'Wavy', label: 'Wavy' },
    { value: 'Shaved', label: 'Shaved' },
    { value: 'Perfecto', label: 'Perfecto' },
    { value: 'Curly', label: 'Curly' },
    { value: 'FreeHair', label: 'FreeHair' },
    { value: 'Business', label: 'Business' },
    { value: 'Medium', label: 'Medium' },
    { value: 'Shabby', label: 'Shabby' },
    { value: 'Long', label: 'Long' },
    { value: 'Razor', label: 'Razor' }
  ];

const hairColorOptions = [
    { value: 'Black', label: 'Black' },
    { value: 'Brown', label: 'Brown' },
    { value: 'Blonde', label: 'Blonde' },
    { value: 'White', label: 'White' },
    { value: 'Red', label: 'Red' },
    { value: 'Pink', label: 'Pink' },
    { value: 'Blue', label: 'Blue' }
  ];

const facialHairStyleOptions = [
    { value: 'CleanShave', label: 'CleanShave' },
    { value: 'Garibaldi', label: 'Garibaldi' },
    { value: 'Hostiles', label: 'Hostiles' },
    { value: 'Rooster', label: 'Rooster' },
    { value: 'Dali', label: 'Dali' },
    { value: 'HandleBar', label: 'HandleBar' },
    { value: 'HorseShoe', label: 'HorseShoe' },
    { value: 'LongMonk', label: 'LongMonk' },
    { value: 'RoosterClassic', label: 'RoosterClassic' },
    { value: 'HandleBarLongMonk', label: 'HandleBarLongMonk' },
    { value: 'RoosterLongMonk', label: 'RoosterLongMonk' }
  ];

const outfitTypeOptions = [
    { value: 'Dress', label: 'Dress' },
    { value: 'Formal', label: 'Formal' },
    { value: 'Party', label: 'Party' },
    { value: 'LeatherJacket', label: 'LeatherJacket' },
    { value: 'Vintage', label: 'Vintage' },
    { value: 'Suit', label: 'Suit' },
    { value: 'Western', label: 'Western' },
    { value: 'Hoodie', label: 'Hoodie' },
    { value: 'Outdoor', label: 'Outdoor' }
  ];

const outfitColorOptions = [
    { value: 'Green', label: 'Green' },
    { value: 'Gold', label: 'Gold' },
    { value: 'Orange', label: 'Orange' },
    { value: 'Black', label: 'Black' },
    { value: 'Silver', label: 'Silver' },
    { value: 'White', label: 'White' },
    { value: 'Designer', label: 'Designer' },
    { value: 'RoseGold', label: 'RoseGold' },
    { value: 'Red', label: 'Red' },
    { value: 'Brown', label: 'Brown' },
    { value: 'Pink', label: 'Pink' },
    { value: 'MetallicPurple', label: 'MetallicPurple' },
    { value: 'Camo', label: 'Camo' },
    { value: 'GreenCamo', label: 'GreenCamo' },
    { value: 'Leather', label: 'Leather' },
    { value: 'RoyalBlue', label: 'RoyalBlue' },
    { value: 'Blue', label: 'Blue' },
    { value: 'Yellow', label: 'Yellow' },
    { value: 'Sparkle', label: 'Sparkle' },
    { value: 'TechCamo', label: 'TechCamo' },
    { value: 'SnowCamo', label: 'SnowCamo' },
    { value: 'Village', label: 'Village' }
  ];

function MetaPeople() {

    const { t, i18n } = useTranslation();
    const [filteredItems, setFilteredItems] = useState([])
    const [items, setItems] = useState(filteredItems.slice(0, 50))
    const [genderFilter, setGenderFilter] = useState([])
    const [skinColorFilter, setSkinColorFilter] = useState([])
    const [eyesSizeFilter, setEyesSizeFilter] = useState([])
    const [hairStyleFilter, setHairStyleFilter] = useState([])
    const [facialHairStyleFilter, setFacialHairStyleFilter] = useState([])
    const [hairColorFilter, setHairColorFilter] = useState([])
    const [outfitTypeFilter, setOutfitTypeFilter] = useState([])
    const [outfitColorFilter, setOutfitColorFilter] = useState([])
    const [modalProps, setModalProps] = useState(MetaPeopleNFTs[0]);
    const [show, setShow] = useState(false)
    const [metamaskInstall, setMetamaskInstall] = useState(typeof window.ethereum !== 'undefined' ? true : false)
    const [connectedWallet, setConnectedWallet] = useState(null)
    const web3 = new Web3(Web3.givenProvider);
    const [txn, setTxn] = useState()
    const [txnStatus, setTxnStatus] = useState(0)

    const openModal = (nft) => {
        setShow(true)
        setModalProps(nft)
    }

    const closeModal = () => {
        setShow(false)
        setTxnStatus(0)
    }

    function getActiveFilters(trait) {
        switch(trait) {
            case "Gender":
                return genderFilter;
                break;
            case "HairStyle":
                return hairStyleFilter;
                break;
            case "FacialHairStyle":
                return facialHairStyleFilter;
                break;
            case "HairColor":
                return hairColorFilter;
                break;
            case "SkinColor":
                return skinColorFilter;
                break;
            case "EyesSize":
                return eyesSizeFilter;
                break;
            case "OutfitType":
                return outfitTypeFilter;
                break;
            case "OutfitColor":
                return outfitColorFilter;
                break;
          }
    }

    async function filterByTrait(nfts, trait) {
        let traitActiveFilters = [];
        traitActiveFilters = getActiveFilters(trait)
        if(traitActiveFilters.length == 0) {
            return nfts   
        }
        const checkedData = await nfts.map(nft => {
            const check = nft.attributes.filter(item => (item.trait_type == trait && traitActiveFilters.indexOf(item.value) > -1)).length
            nft.traitCheck = check > 0 ? true : false
            return nft
        })
        return(checkedData.filter(nft => nft.traitCheck))
    }

    const getSoldMetadataHash = async () => {
        // Contract address
        const address = "0xadD423833A199F493F4DD5ec5Cb0BcEB32AF657e";

        // Flag to omit metadata
        const omitMetadata = false;

        // Get all NFTs
        const { nfts } = await alchemy.nft.getNftsForContract(address, {
            omitMetadata: omitMetadata,
        });

        let i = 1;
        let soldMetadataHash = []
        for (let nft of nfts) {
            soldMetadataHash.push((nft.tokenUri.raw).substring(30))
        }
        return soldMetadataHash;
      };

    const markSoldNFTs = async (data) => {
        let soldHashList = await getSoldMetadataHash()
        for (let item of data) {
            const jsonName = item.name+".json"
            item.sold = soldHashList.indexOf(MetadataJSON[jsonName]) > -1 ? true : false
        }
        return data
    }

    useEffect(() => {
        async function initialLoad() {

            try{
                let genderFilteredNFTs = await filterByTrait(MetaPeopleNFTs, "Gender")
                let hairStyleFilteredNFTs = await filterByTrait(genderFilteredNFTs, "HairStyle")
                let facialHairStyleFilteredNFTs = await filterByTrait(hairStyleFilteredNFTs, "FacialHairStyle")
                let hairColorFilteredNFTs = await filterByTrait(facialHairStyleFilteredNFTs, "HairColor")
                let skinColorFilteredNFTs = await filterByTrait(hairColorFilteredNFTs, "SkinColor")
                let eyesSizeFilteredNFTs = await filterByTrait(skinColorFilteredNFTs, "EyesSize")
                let outfitTypeFilteredNFTs = await filterByTrait(eyesSizeFilteredNFTs, "OutfitType")
                let outfitColorFilteredNFTs = await filterByTrait(outfitTypeFilteredNFTs, "OutfitColor")
                let soldMarkedNFTs = await markSoldNFTs(outfitColorFilteredNFTs)
                setFilteredItems(soldMarkedNFTs)
                setItems(soldMarkedNFTs.slice(0,50))
            } catch(e) {
                console.log(e);
            }
        }
        initialLoad()
    }, [genderFilter, skinColorFilter, hairStyleFilter, facialHairStyleFilter, hairColorFilter, eyesSizeFilter, outfitTypeFilter, outfitColorFilter])


    

    const renderNFTs = (nftIndex) => {
        const currentNFT = filteredItems[nftIndex]

        const NFTImage = `https://metapeople-nfts.b-cdn.net/QmWGezrW74i4TqjHZ6WaxFyurdY4iNq1BRC6yfrXWcHHC6/`+(currentNFT.name)+'.png?width=600'
    
        return (
                <Col fluid className={styles.nftContainer} style={{ backgroundImage: `url(${NFTImage})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center" }} onClick={() => openModal(currentNFT)}>
                    {currentNFT.sold ? <div className={styles.badgeContainer}><div className={styles.badge}><AiFillCarryOut className={styles.sold} /> Sold</div></div> : ''}
                </Col>
        );
    }

    const fetchData = () => {
        setItems(items => items.concat(filteredItems.slice(items.length, items.length + 50)))
    }

    const updateFilter = (trait, value) => {
        
        if(trait == "Gender") {
            if(!genderFilter.includes(value)) {
                setGenderFilter(prevArr => [...prevArr, value])
            } else {
                setGenderFilter(genderFilter.filter(item => item !== value));
            }
        }
        else if(trait == "HairStyle") {
            if(!hairStyleFilter.includes(value)) {
                setHairStyleFilter(prevArr => [...prevArr, value])
            } else {
                setHairStyleFilter(hairStyleFilter.filter(item => item !== value));
            }
        }

        else if(trait == "FacialHairStyle") {
            if(!facialHairStyleFilter.includes(value)) {
                setFacialHairStyleFilter(prevArr => [...prevArr, value])
            } else {
                setFacialHairStyleFilter(facialHairStyleFilter.filter(item => item !== value));
            }
        }

        else if(trait == "HairColor") {
            if(!hairColorFilter.includes(value)) {
                setHairColorFilter(prevArr => [...prevArr, value])
            } else {
                setHairColorFilter(hairColorFilter.filter(item => item !== value));
            }
        }

        else if(trait == "SkinColor") {
            if(!skinColorFilter.includes(value)) {
                setSkinColorFilter(prevArr => [...prevArr, value])
            } else {
                setSkinColorFilter(skinColorFilter.filter(item => item !== value));
            }
        }

        else if(trait == "EyesSize") {
            if(!eyesSizeFilter.includes(value)) {
                setEyesSizeFilter(prevArr => [...prevArr, value])
            } else {
                setEyesSizeFilter(eyesSizeFilter.filter(item => item !== value));
            }
        }

        else if(trait == "OutfitType") {
            if(!outfitTypeFilter.includes(value)) {
                setOutfitTypeFilter(prevArr => [...prevArr, value])
            } else {
                setOutfitTypeFilter(outfitTypeFilter.filter(item => item !== value));
            }
        }

        else if(trait == "OutfitColor") {
            if(!outfitColorFilter.includes(value)) {
                setOutfitColorFilter(prevArr => [...prevArr, value])
            } else {
                setOutfitColorFilter(outfitColorFilter.filter(item => item !== value));
            }
        }
    }

    const clearFilters = () => {
        window.location.reload()
    }

    const getAttributesList = (attributes) => {
            return attributes.map(item => {
                return (
                    <div className="item">
                        <div className="label">{item.trait_type}</div>
                        <div className="value">{item.value}</div>
                    </div>
                )
            })
        }

    // Web3 related functions starts here

    const connectMetamask = async() => {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setConnectedWallet(accounts[0]);
    }


    const mintNFT = async(nftItem) => {

        const nftDeployerContractAddress = '0x48118158c6D0F7d90c703B7203aF7E2A401281e4';
        const nftDeployerABI = require('./../ABI/MetaPeopleDeployerABI.json')

        const nftDeployerContract = new web3.eth.Contract(nftDeployerABI, nftDeployerContractAddress);

        const price = 0.12;
        //const metadataCID = "QmfWuxHbCfBwiCa4Dekm51LbKfgduFF9BEUZyk6ya72acq"
        const metadataCID = MetadataJSON[nftItem.name+'.json']

        // nftDeployerContract.methods.mintNft(connectedWallet, metadataCID)
        // .send({ from: connectedWallet, value: web3.utils.toWei(price.toString(), 'ether') }, (res, err) => {
        //     if (res) {
        //         console.log(res);
        //     }
        //     if (err) {
        //         console.error(err);
        //     }
        // });

        let gasPriceValue = 0
        await web3.eth.getGasPrice()
            .then(value => {
                gasPriceValue = value;
            })

        let gasLimit = 0
        await web3.eth.getBlock("latest")
            .then(value => {
                gasLimit = Math.round(value.gasLimit / value.transactions.length)
                console.log(gasLimit)
            })

        nftDeployerContract.methods.mintNft(connectedWallet, metadataCID)
            .send({ from: connectedWallet, value: web3.utils.toWei(price.toString(), 'ether'), gas: gasLimit, gasPrice: gasPriceValue })
            .on('transactionHash', function(hash) {
                setTxn(hash);
                setTxnStatus(1)
                console.log(txn, "hash")
            })
            .on('receipt', function(receipt) {
                setTxnStatus(2)
            })
    }

    // Web3 related functions ends here

    return (
        <div className={styles.metaPeople}>
            {/* Hero starts here */}
            <div className={styles.hero}>
                <video
                    autoPlay
                    loop
                    muted
                    className={styles.bgVideo}
                    >
                    <source src={HeroVideo} type="video/mp4" />
                </video>
                <div className={styles.overlay}>
                    <Container className={styles.heroContainer}>
                        <Header />
                    </Container>
                </div>
            </div>
            {/* Hero ends here */}

            {/* MetaPeople NFTs collections starts here */}
            <div className={styles.collection}>
                <Container className={styles.collectionContainer}>
                    <div className={styles.filtersContainer}>
                            <Dropdown>
                                <Dropdown.Toggle className={styles.filterTitle} id="dropdown-basic">
                                    Gender
                                </Dropdown.Toggle>

                                <Dropdown.Menu className={styles.filterMenu}>
                                    <Form>
                                        {genderOptions.map((item) => (
                                            <Form.Check className={styles.filterMenuItem} type="checkbox" id={item.value} label={item.label} onChange={(e) => updateFilter("Gender", item.value)} />
                                        ))}
                                    </Form>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown>
                                <Dropdown.Toggle className={styles.filterTitle} id="dropdown-basic">
                                    Skin Tone
                                </Dropdown.Toggle>

                                <Dropdown.Menu className={styles.filterMenu}>
                                    <Form>
                                        {skinOptions.map((item) => (
                                            <Form.Check className={styles.filterMenuItem} type="checkbox" id={item.value} label={item.label} onChange={(e) => updateFilter("SkinColor", item.value)} />
                                        ))}
                                    </Form>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown>
                                <Dropdown.Toggle className={styles.filterTitle} id="dropdown-basic">
                                    Eyes
                                </Dropdown.Toggle>

                                <Dropdown.Menu className={styles.filterMenu}>
                                    <Form>
                                        {eyeOptions.map((item) => (
                                            <Form.Check className={styles.filterMenuItem} type="checkbox" id={item.value} label={item.label} onChange={(e) => updateFilter("EyesSize", item.value)} />
                                        ))}
                                    </Form>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown>
                                <Dropdown.Toggle className={styles.filterTitle} id="dropdown-basic">
                                    Hair Style
                                </Dropdown.Toggle>

                                <Dropdown.Menu className={styles.filterMenu}>
                                    <Form>
                                        {hairStyleOptions.map((item) => (
                                            <Form.Check className={styles.filterMenuItem} type="checkbox" id={item.value} label={item.label} onChange={(e) => updateFilter("HairStyle", item.value)} />
                                        ))}
                                    </Form>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown>
                                <Dropdown.Toggle className={styles.filterTitle} id="dropdown-basic">
                                    Hair Color
                                </Dropdown.Toggle>

                                <Dropdown.Menu className={styles.filterMenu}>
                                    <Form>
                                        {hairColorOptions.map((item) => (
                                            <Form.Check className={styles.filterMenuItem} type="checkbox" id={item.value} label={item.label} onChange={(e) => updateFilter("HairColor", item.value)} />
                                        ))}
                                    </Form>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown>
                                <Dropdown.Toggle className={styles.filterTitle} id="dropdown-basic">
                                    Facial Hair
                                </Dropdown.Toggle>

                                <Dropdown.Menu className={styles.filterMenu}>
                                    <Form>
                                        {facialHairStyleOptions.map((item) => (
                                            <Form.Check type="checkbox" id={item.value} label={item.label} onChange={(e) => updateFilter("FacialHairStyle", item.value)} />
                                        ))}
                                    </Form>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown>
                                <Dropdown.Toggle className={styles.filterTitle} id="dropdown-basic">
                                    Outfit Type
                                </Dropdown.Toggle>

                                <Dropdown.Menu className={styles.filterMenu}>
                                    <Form>
                                    {outfitTypeOptions.map((item) => (
                                            <Form.Check type="checkbox" id={item.value} label={item.label} onChange={(e) => updateFilter("OutfitType", item.value)} />
                                        ))}
                                    </Form>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown>
                                <Dropdown.Toggle className={styles.filterTitle} id="dropdown-basic">
                                    Outfit Color
                                </Dropdown.Toggle>

                                <Dropdown.Menu className={styles.filterMenu}>
                                    <Form>
                                        {outfitColorOptions.map((item) => (
                                            <Form.Check type="checkbox" id={item.value} label={item.label} onChange={(e) => updateFilter("OutfitColor", item.value)} />
                                        ))}
                                    </Form>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Button variant="outline" onClick={() => clearFilters()}>Clear Filters</Button>
                    </div>
                    <div>
                            <InfiniteScroll
                                dataLength={items.length} //This is important field to render the next data
                                next={fetchData}
                                style={{ textAlign: "center" }}
                                hasMore={true}>
                                {/* {Preview} */}
                                <Row>
                                    {items.map((nft, index) => renderNFTs(index))}
                                </Row>
                            </InfiniteScroll>
                    </div>
                </Container>
            </div>
            {/* MetaPeople NFTs collections ends here */}
{/*             
            <Modal show={modalProps.show} animation={false} centered  dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title">
                <Modal.Body className='show-grid'>
                    <div className='previewImage' style={{ backgroundImage: `url(${process.env.PUBLIC_URL + `/MetaPeople/800/${modalProps.nft.name}.png`})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "top-center" }}>
                    </div>
                    <div className="attributesContainer"></div>
                </Modal.Body>
            </Modal> */}

            <Modal
                show={show}
                onHide={() => closeModal()}
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Body>
                <div className='previewImage' style={{ backgroundImage: `url(${`https://metapeople-nfts.b-cdn.net/QmWGezrW74i4TqjHZ6WaxFyurdY4iNq1BRC6yfrXWcHHC6/`+(modalProps.name)+'.png?width=800'})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}></div>
                <div className="attributesContainer">
                    <div className={"dataContainer"}>
                        <div className={"header"}>
                            <div className={"title"}>Attributes</div>
                            <div className={"name"}>{modalProps.name}</div>
                        </div>
                        <div className={"content"}>
                            {getAttributesList(modalProps.attributes)}
                        </div>
                        {
                            !modalProps.sold ? 
                                <div className={"price"}>
                                    <div className={"value"}>0.12 ETH</div>
                                </div> : "" 
                        }
                        
                        {
                            connectedWallet == null ?  '' : <div className='message'>Connected Wallet <span className="value">{connectedWallet.substring(0, 5)+'...'+connectedWallet.slice(-3)}</span></div>
                        }
                    </div>
                    {
                        !modalProps.sold ? 
                            <div className='footerContainer'>
                                { 
                                    !metamaskInstall ? <div className="warning">MetaMask not installed. Please install Metamask extension to proceed.</div> : 
                                    connectedWallet == null ? <Button variant="black" onClick={() => connectMetamask()}>Connect your wallet to Mint</Button> :
                                    txnStatus == 1 ?  <div className="inprogress"><div className='message blinkMe'>Minting in progress.</div><a href={"https://etherscan.io/tx/"+txn} target="_blank">View transaction on Etherscan</a></div> :
                                    txnStatus == 2 ?  <div className="done"><div className='message'>Minted Successfully</div><a href={"https://etherscan.io/tx/"+txn} target="_blank">View transaction on Etherscan</a></div> : 
                                    modalProps.sold ?  <div className="sold">Sold</div> :
                                    <Button variant="green" onClick={() => mintNFT(modalProps)}>Mint this NFT</Button>
                                }   
                            </div> : ''
                    }
                </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default MetaPeople
