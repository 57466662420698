import React, { useState } from 'react'
import { Container, Tab, Nav, Row, Col, Image, Button, DropdownButton, Dropdown, Table } from 'react-bootstrap'
import styles from './Community.module.scss'
import Header from './../components/Header'
import { Tweet } from 'react-twitter-widgets'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import { IoStopCircleSharp } from 'react-icons/io5'
import TwitterLogo from './../assets/images/twitter_large.svg'
import TelegramLogo from './../assets/images/telegram_large.svg'
import RedditLogo from './../assets/images/reddit_large.svg'
import MediumLogo from './../assets/images/medium_large.svg'
import GithubLogo from './../assets/images/github_large.svg'
import DiscordLogo from './../assets/images/discord_large.svg'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'

function Community() {

    const { t, i18n } = useTranslation();

    return (
        <div className={styles.community}>
             {/* HeroMini container begins here */}
             <div className={styles.heroMini}>
                <div className={styles.overlay}>
                    <Container className={styles.heroMiniContainer}>
                            <Header />
                            <div className={styles.content}>
                                <div className={styles.title}>Community</div>
                            </div>
                    </Container>
                </div>
            </div>
            {/* HeoMini container ends here */}

            {/* Links container begins here */}
            <div className={styles.links}>
                <Container className={styles.linksContainer}>
                        <div className={styles.title}>
                            Be a part of <span className={styles.light}>Meta</span><span className={styles.medium}>Reset</span> community and lets start <span className={styles.thick}>living</span>, <span className={styles.thick}>sharing</span> and <span className={styles.thick}>building</span> our alternate life on MetaWorld
                        </div>
                        <div className={styles.tileContainer}> 
                            <a href="https://twitter.com/metareset" target="_blank" className={styles.item}>
                                <img
                                    src={TwitterLogo}
                                    width="auto"
                                    height="60"
                                    className="d-inline-block align-top"
                                    alt="MetaReset Logo" />
                            </a>
                            <a href="https://t.me/RESETverse" target="_blank" className={styles.item}>
                                <img
                                    src={TelegramLogo}
                                    width="auto"
                                    height="60"
                                    className="d-inline-block align-top"
                                    alt="MetaReset Logo" />
                            </a>
                            <a href="https://www.reddit.com/r/metareset" target="_blank" className={styles.item}>
                                <img
                                    src={RedditLogo}
                                    width="auto"
                                    height="60"
                                    className="d-inline-block align-top"
                                    alt="MetaReset Logo" />
                            </a>
                            <a href="https://medium.com/metareset" target="_blank" className={styles.item}>
                                <img
                                    src={MediumLogo}
                                    width="auto"
                                    height="60"
                                    className="d-inline-block align-top"
                                    alt="MetaReset Logo" />
                            </a>
                            <a href="https://github.com/metareset" target="_blank" className={styles.item}>
                                <img
                                    src={GithubLogo}
                                    width="auto"
                                    height="60"
                                    className="d-inline-block align-top"
                                    alt="MetaReset Logo" />
                            </a>
                            <a href="https://github.com/metareset" target="_blank" className={styles.item}>
                                <img
                                    src={DiscordLogo}
                                    width="auto"
                                    height="60"
                                    className="d-inline-block align-top"
                                    alt="MetaReset Logo" />
                            </a>
                        </div>
                </Container>
            </div>
            {/* Links container ends here */}

             {/* Testimonials container starts here */}
             <div className={styles.tweets}>
                <Container className={styles.tweetsContainer}>
                    <div className={styles.title}> {t('Community.Voices.title')} </div>
                    <div className={styles.desc}> {t('Community.Voices.desc')} </div>
                    <ResponsiveMasonry
                        columnsCountBreakPoints={{350: 1, 750: 3, 900: 4}}>
                        <Masonry>
                            <div className={styles.tile}><Tweet tweetId="1618212793731407872" /></div>
                            <div className={styles.tile}><Tweet tweetId="1617632381338517507" /></div>
                            <div className={styles.tile}><Tweet tweetId="1616737031907844096" /></div>
                            <div className={styles.tile}><Tweet tweetId="1612666686213328896" /></div>
                            <div className={styles.tile}><Tweet tweetId="1613550590348476416" /></div>
                            <div className={styles.tile}><Tweet tweetId="1616704046835843072" /></div>
                            <div className={styles.tile}><Tweet tweetId="1587516133866889222" /></div>
                            <div className={styles.tile}><Tweet tweetId="1597721980387889153" /></div>
                            <div className={styles.tile}><Tweet tweetId="1617091626622402561" /></div>
                            <div className={styles.tile}><Tweet tweetId="1616925806030389248" /></div>
                            <div className={styles.tile}><Tweet tweetId="1597715479518330881" /></div>
                            <div className={styles.tile}><Tweet tweetId="1542242176498794496" /></div>
                            <div className={styles.tile}><Tweet tweetId="1536635767296266240" /></div>
                            <div className={styles.tile}><Tweet tweetId="1535759144539537418" /></div>
                            <div className={styles.tile}><Tweet tweetId="1539134508930322433" /></div>
                            <div className={styles.tile}><Tweet tweetId="1537333970672680960" /></div>
                            <div className={styles.tile}><Tweet tweetId="1536564673679605761" /></div>
                            <div className={styles.tile}><Tweet tweetId="1533849787111378945" /></div>
                            <div className={styles.tile}><Tweet tweetId="1535402678959972358" /></div>
                            <div className={styles.tile}><Tweet tweetId="1535759144539537418" /></div>
                            <div className={styles.tile}><Tweet tweetId="1542532312071561218" /></div>
                        </Masonry>
                    </ResponsiveMasonry>
                </Container>
            </div>
            {/* Testimonials container ends here */}

        </div>
    )
}

export default Community